import EzComponent              from '@frontend/components/EzComponent';

import ReCAPTCHAV2                from "react-google-recaptcha";

import InvisibleReCaptcha       from 'react-google-invisible-recaptcha';

import '@frontend/components/google-recaptcha/index.less';

export class GoogleRecaptcha extends EzComponent{

  setCaptchaRef(captchaRef){
    this.recaptcha = captchaRef;
    this.props.setCaptchaRef(captchaRef);
  }
  saveCaptcha = (value)=>{
      this.props.onChange(value);
      if (this.props.isInvisible){
          this.props.invisibleCaptchaAction(null,value);
      }
  }
  render(){
    const {
      googleRecaptchaKey,isInvisible,className=''
     } = this.props;
    return (
      <div className={`google-recaptcha ${isInvisible ? `invisible ${className}` :''}`}>
          {
            isInvisible ?
                <InvisibleReCaptcha
                    ref={ ref => this.setCaptchaRef(ref) }
                    sitekey={googleRecaptchaKey}
                    onResolved={ () => this.saveCaptcha(this.recaptcha.getResponse()) } />
            :
                <ReCAPTCHAV2 className={className}
                    sitekey={googleRecaptchaKey}
                    onChange={this.saveCaptcha} />
          }
    </div>

    );
  }
}

export default GoogleRecaptcha;
