import EzComponent              from '@frontend/components/EzComponent';

import ReCAPTCHA                from "react-google-recaptcha";
import { connect }              from 'react-redux'
import { subscribeNewsletter }  from '@frontend/actions';

import '@frontend/components/newsletter/newsletter.less';

export class Newsletter extends EzComponent{
  state = {
    email: '',
    submitted: false,
    error: '',
    showReCaptcha: false,
    captcha:'',
    storeConfiguration:{}
  }

  handleChange = (ev)=>{
    this.setState({email:ev.target.value,submitted:false});
  }
  showReCaptcha=(ev)=>{
    document.addEventListener('click', this.handleOutsideClick, false);
    this.setState({showReCaptcha:true});
  }
  ocultReCaptcha=(ev)=>{
    this.setState({showReCaptcha:false});
  }
  subscribeNewsletter = async (ev)=>{
    const { email,captcha } = this.state;
    ev.preventDefault();
    this.setState({showReCaptcha:false});
    if (this.validateFields()){
      let variables = {
        email,
        captcha
      };
      await this.props.subscribeNewsletter(this.context,variables);
      this.setState({ submitted:true,email:'' })
    }

  }

  validateFields(){
    const {email,captcha} = this.state;
    // TODO: Add Validators
    // const validator = validators.get('email');
    // const validation = validator(email);
    // if (validation!==null){
    //   this.setState({submitted:false,error:validation});
    //   return false;
    // }
    if(!captcha.length){
      let error = this.i18n.t('newsletter.recaptchaError');
      this.setState({submitted:false,showReCaptcha:true,error});
      return false;
    }

    return true;

  }

  saveCaptcha=(value)=>{
    this.setState({captcha:value});
  }

  handleOutsideClick = (ev) =>{
    // ignore clicks on the component itself

    if (this.captchaRef==null || this.captchaRef.contains(ev.target)) {
      return;
    }
    this.ocultReCaptcha(ev);
  }
  setCaptchaRef(captchaRef){
    this.captchaRef = captchaRef;
  }
  render(){
    const { email,
            submitted,
            error,
            showReCaptcha,
            captcha,
            subscribeResult='',
            } = this.state;
    const {
      storeConfiguration:{googleRecaptchaKey},
      subscriptionResult,
      isMobile
     } = this.props;
     const col= (isMobile)? "col-12":"col-3";
    return (
      <div className="subscribe-container" ref={ node => { this.setCaptchaRef(node)}}>
        <div className="subscribe-title">
          <span>{this.i18n.t('newsletter.subscribe')}</span>
        </div>
        <div className="subscribe-info">
          <span>{this.i18n.t('newsletter.subscribeInfo')}</span>
        </div>
        <div className="subscribe-form">
          <input className="subscribe-email" name="email"
              value={email} type="email" id="newsletter" placeholder={this.i18n.t('newsletter.emailPlaceholder')}
              required onChange={this.handleChange} onClick={this.showReCaptcha} />
          {
            (error.length!==0) &&
            <p className="subscribe-email-error">{error}</p>
          }
          <div className="subscribe-actions">
            <button onClick={this.subscribeNewsletter}></button>
          </div>
          {
            showReCaptcha &&

            <ReCAPTCHA className="subscribe-recaptcha" sitekey={googleRecaptchaKey}
                      onChange={this.saveCaptcha} />
          }
        </div>
        {
          (subscriptionResult && subscriptionResult.ok) &&
            <span className="subscribe-result">{subscriptionResult.response}</span>
        }
        {
          (subscriptionResult && !subscriptionResult.ok) &&
            <span className="subscribe-result error">{this.i18n.t('newsletter.subscriptionError')}</span>
        }
      </div>
    );
  }
}
function mapStateToProps({isMobile,storeConfig,newsletter}){
  return {
    storeConfiguration:storeConfig,
    subscriptionResult: newsletter,
    isMobile
  }
}
export default connect(mapStateToProps,{subscribeNewsletter})(Newsletter);
